import { getAverageRating, getLeagueProfile, getRatingDistribution, getRatingsPage } from '../api/requests'
import axios from 'axios'

export const loadProfile = async (name, dispatch) => {
    dispatch({ type: 'LOAD_START' })
    try {
        const leagueProfile = await getLeagueProfile(name)
        const { uuid } = leagueProfile
        const [ratingsPage, distribution, average] = await Promise.all([
            getRatingsPage(uuid),
            getRatingDistribution(uuid),
            getAverageRating(uuid)
        ])
        dispatch({ type: 'SET_LEAGUE_PROFILE', leagueProfile })
        dispatch({ type: 'SET_RATINGS_PAGE', ratingsPage })
        dispatch({ type: 'SET_DISTRIBUTION', distribution })
        dispatch({ type: 'SET_AVERAGE', average })
    } catch (e) {
        dispatch({ type: 'SET_ERROR', error: e })
    }
    dispatch({ type: 'LOAD_COMPLETE' })
}

export const loadRatings = (uuid, pageNumber = 0, dispatch) => {
    dispatch({ type: 'LOAD_START' })
    const pageRequest = getRatingsPage(uuid, pageNumber)
    const distributionRequest = getRatingDistribution(uuid)
    const averageRequest = getAverageRating(uuid)
    axios
        .all([pageRequest, distributionRequest, averageRequest])
        .then(
            axios.spread((page, distribution, average) => {
                dispatch({ type: 'SET_RATINGS_PAGE', ratingsPage: page })
                dispatch({ type: 'SET_DISTRIBUTION', distribution })
                dispatch({ type: 'SET_AVERAGE', average })
            })
        )
        .catch((error) => dispatch({ type: 'SET_ERROR', error }))
        .finally(() => dispatch({ type: 'LOAD_COMPLETE' }))
}

export const loadPage = (uuid, page, dispatch) => {
    getRatingsPage(uuid, page).then((ratingsPage) => dispatch({ type: 'SET_RATINGS_PAGE', ratingsPage }))
}
