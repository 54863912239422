import React from 'react'
import HomePage from '../component/home/HomePage'
import Header from '../component/common/Header'

const RootRoute = () => {
    return (
        <React.Fragment>
            <Header />
            <HomePage />
        </React.Fragment>
    )
}

export default RootRoute
