import React from 'react'
import ContentContainer from '../common/ContentContainer'
import { Box, Typography } from '@mui/material'
import RatingButton from './RatingButton'

const NoReportedRatings = ({ name, uuid }) => {
    return (
        <ContentContainer>
            <Box textAlign="center">
                <Typography variant="h4" fontWeight={600}>
                    {name} has no ratings!
                </Typography>
                <Box mt={1}>
                    <Typography>Be the first to give them an astounding review.</Typography>
                    <Box mt={1}>
                        <RatingButton name={name} uuid={uuid} withSubmitRefresh />
                    </Box>
                </Box>
            </Box>
        </ContentContainer>
    )
}

export default NoReportedRatings
