import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileRoute from './routes/ProfileRoute'
import React from 'react'
import RootRoute from './routes/RootRoute'
import AppThemeProvider from './theme/AppThemeProvider'
import ErrorPage from './component/error/ErrorPage'
import { CssBaseline } from '@mui/material'
import LeaderboardRoute from './routes/LeaderboardRoute'

const ApplicationRouter = () => {
    return (
        <AppThemeProvider>
            <CssBaseline />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<RootRoute />} errorElement={<ErrorPage />} />
                    <Route path="/profile/:user" element={<ProfileRoute />} errorElement={<ErrorPage />} />
                    <Route path="/leaderboard" element={<LeaderboardRoute />} errorElement={<ErrorPage />} />
                </Routes>
            </BrowserRouter>
        </AppThemeProvider>
    )
}

export default ApplicationRouter
