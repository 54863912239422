import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material'

const BackdropSpinner = ({ open, title }) => {
    return (
        <Backdrop open={open} sx={{ zIndex: 999 }}>
            <Stack spacing={2} alignItems="center" justifyContent="center">
                <CircularProgress />
                <Typography>{title}</Typography>
            </Stack>
        </Backdrop>
    )
}

export default BackdropSpinner
