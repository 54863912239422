import React, { useId, useRef, useState } from 'react'
import { Box, IconButton, InputLabel, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { Link } from 'react-router-dom'

const ProfileSearch = () => {
    const [search, setSearch] = useState('')
    const buttonRef = useRef()
    const labelId = useId()

    const onSubmit = (event) => {
        event.preventDefault()
        buttonRef?.current?.click()
    }

    return (
        <Box component="form" onSubmit={onSubmit} width="100%" pt={1} pb={1} pr={3} pl={3} display="flex">
            <div style={{ flexGrow: 1 }}>
                <InputLabel id={labelId} sx={{ fontWeight: 600, fontSize: 12 }} variant="standard">
                    Search
                </InputLabel>
                <TextField
                    aria-labelledby={labelId}
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    placeholder="Your username..."
                    variant="standard"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <IconButton ref={buttonRef} type="submit" size="large" LinkComponent={Link} to={`/profile/${search}`}>
                <SearchIcon />
            </IconButton>
        </Box>
    )
}

export default ProfileSearch
