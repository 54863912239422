import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import RatingButton from './RatingButton'
import { useProfileContext } from '../../context/ProfileContextProvider'

const ProfileCard = () => {
    const { state } = useProfileContext()
    const { leagueProfile } = state
    return (
        <Stack spacing={1} alignItems="center">
            <Box position="relative" alignItems="center" display="flex" flexDirection="column">
                <Avatar
                    alt="Profile Image"
                    src={`https://raw.communitydragon.org/12.19/game/assets/ux/summonericons/profileicon${leagueProfile.profileIcon}.png`}
                    sx={{ width: 96, height: 96, border: '5px solid black' }}
                />
                <Typography
                    position="relative"
                    top={-16}
                    fontSize={14}
                    borderRadius={10}
                    width={50}
                    bgcolor="black"
                    fontWeight={600}
                    textAlign="center"
                >
                    {leagueProfile.level}
                </Typography>
            </Box>
            <Typography fontSize={36} textAlign="center" fontWeight={600}>
                {leagueProfile.summonerName}
            </Typography>
            <Typography variant="caption">Average Rating</Typography>
            <Typography fontSize={40} fontWeight={600}>
                {state.average}/5
            </Typography>
            <Divider />
            <RatingButton name={leagueProfile.summonerName} uuid={leagueProfile.uuid} withSubmitRefresh />
        </Stack>
    )
}

export default ProfileCard
