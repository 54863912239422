import ContentContainer from '../common/ContentContainer'
import { Box, Button, TextField, Typography } from '@mui/material'
import TeammateQualityRating from './TeammateQualityRating'
import GameRoleDropdown from './GameRoleDropdown'
import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import BackdropSpinner from '../common/BackdropSpinner'

const RatingForm = ({ teammate, submitting, onClickSubmit }) => {
    const [form, setForm] = useState({ role: 'unknown', rating: 0, comment: '' })
    return (
        <ContentContainer>
            <Typography variant="h5" fontWeight={600}>
                Rate {teammate}
            </Typography>
            <Box component="form" sx={{ paddingTop: 3 }}>
                <Stack spacing={3}>
                    <TeammateQualityRating
                        quality={form.rating}
                        onQualityChange={(value) => setForm({ ...form, rating: value || 1 })}
                    />
                    <GameRoleDropdown
                        value={form.role}
                        onChange={(event) => setForm({ ...form, role: event.target.value })}
                    />
                    <TextField
                        label="Comment"
                        placeholder="Great player. Better friend."
                        fullWidth
                        multiline
                        minRows={2}
                        value={form.comment}
                        onChange={(e) => setForm({ ...form, comment: e.target.value })}
                        inputProps={{ maxLength: 200 }}
                    />
                </Stack>
                <Button
                    disabled={submitting}
                    onClick={() => onClickSubmit(form)}
                    sx={{ marginTop: 4 }}
                    variant="contained"
                >
                    Submit
                </Button>
                <BackdropSpinner open={submitting} title="Submitting Rating" />
            </Box>
        </ContentContainer>
    )
}

export default RatingForm
