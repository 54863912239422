import { Card, CardContent, Rating, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import React from 'react'
import { getRelativeTime } from '../../utils'

const TeammateRating = ({ rating }) => {
    return (
        <Card elevation={0}>
            <CardContent>
                <Stack direction="row" spacing={2}>
                    <Avatar src="https://raw.communitydragon.org/12.20/game/assets/ux/summonericons/profileicon0.png" />
                    <Stack>
                        <Typography>Previous Teammate</Typography>
                        <Stack direction="row" sx={{ mt: 1 }} spacing={1}>
                            <Rating name="teammate-quality" value={rating.rating} precision={1} size="small" readOnly />
                            <Typography variant="caption">{getRelativeTime(rating.timeCreated)}</Typography>
                        </Stack>
                        {rating.comment && (
                            <Typography sx={{ mt: 2, maxWidth: 500, wordWrap: 'break-word' }}>
                                {rating.comment}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default TeammateRating
