import React, { useContext, useEffect, useReducer } from 'react'
import profileReducer, { createDefaultReducerState } from '../reducer/profile-reducer'
import { useParams } from 'react-router-dom'
import { loadProfile } from '../reducer/profile-reducer-actions'

const ProfileContext = React.createContext({
    state: createDefaultReducerState(),
    dispatch: () => {}
})

export const useProfileContext = () => useContext(ProfileContext)

const ProfileContextProvider = ({ children }) => {
    const { user } = useParams()
    const [state, dispatch] = useReducer(profileReducer, createDefaultReducerState())

    useEffect(() => {
        if (!user) return
        loadProfile(user, dispatch)
    }, [user])

    return <ProfileContext.Provider value={{ state, dispatch }}>{children}</ProfileContext.Provider>
}

export default ProfileContextProvider
