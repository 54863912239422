import { useProfileContext } from '../../context/ProfileContextProvider'
import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Box,
    CircularProgress,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import { getMostRecentGame } from '../../api/requests'
import ContentContainer from '../common/ContentContainer'
import RatingButton from '../profile/RatingButton'
import { Link } from 'react-router-dom'

const blueTeamId = 100
const redTeamId = 200

const RecentMatch = () => {
    const { state } = useProfileContext()
    const { leagueProfile } = state
    const [match, setMatch] = useState(null)
    const [, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!leagueProfile) return
        const { uuid } = leagueProfile
        setError(null)
        setLoading(true)
        getMostRecentGame(uuid)
            .then((data) => setMatch(data))
            .catch((error) => setError(error))
            .finally(() => setLoading(false))
    }, [leagueProfile])

    if (loading) {
        return <RecentMatchSkeleton />
    }

    return (
        <ContentContainer>
            <Typography variant="h5" fontWeight={600}>
                Most Recent Game
            </Typography>
            <Grid mt={2} container>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                    <Typography fontWeight={500} sx={{ color: '#ff5757' }}>
                        Red Team
                    </Typography>
                    <Team id={redTeamId} participants={match?.info?.participants} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                    <Typography fontWeight={500} sx={{ color: '#47b3ff' }}>
                        Blue Team
                    </Typography>
                    <Team id={blueTeamId} participants={match?.info?.participants} />
                </Grid>
            </Grid>
        </ContentContainer>
    )
}

const Team = ({ id, participants }) => {
    if (!participants) return null
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>PLAYER</TableCell>
                    <TableCell>K/D/A</TableCell>
                    <TableCell aria-label="Action" />
                </TableRow>
            </TableHead>
            <TableBody>
                {participants
                    .filter((p) => p.teamId === id)
                    .map((p) => (
                        <ParticipantRow key={p.puuid} participant={p} />
                    ))}
            </TableBody>
        </Table>
    )
}

const ParticipantRow = ({ participant }) => {
    const { kills, deaths, assists } = participant
    const { state } = useProfileContext()
    const isPlayerOnProfile = state.leagueProfile?.summonerName === participant.summonerName
    return (
        <TableRow>
            <TableCell>
                <Stack spacing={1} direction="row" alignItems="center">
                    <Avatar
                        src={`https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/v1/champion-icons/${participant.championId}.png`}
                        sx={{ border: `2px solid ${isPlayerOnProfile ? '#ff9f07' : 'black'}` }}
                    />
                    <Link to={`/profile/${participant.summonerName}`}>
                        <Typography>{participant.summonerName}</Typography>
                    </Link>
                </Stack>
            </TableCell>
            <TableCell>{`${kills}/${deaths}/${assists}`}</TableCell>
            <TableCell>
                <RatingButton
                    name={participant.summonerName}
                    uuid={participant.puuid}
                    buttonName="Rate"
                    buttonProps={{ variant: 'outlined' }}
                />
            </TableCell>
        </TableRow>
    )
}

const RecentMatchSkeleton = () => {
    return (
        <ContentContainer>
            <Typography variant="h5" fontWeight={600}>
                Most Recent Game
            </Typography>
            <Box padding={10} display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        </ContentContainer>
    )
}

export default RecentMatch
