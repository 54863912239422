import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const ErrorPage = ({ errorCode }) => {
    return (
        <Box mt={15} ml={25} mr={25} mb={15} textAlign="center">
            <Typography variant="h5" fontWeight={600}>
                Oops! Something went wrong.
            </Typography>
            <Typography mt={1}>Error code: {errorCode}</Typography>
            <Link style={{ textDecoration: 'none' }} to="/">
                <Button sx={{ mt: 5 }} variant="contained">
                    Return to Home
                </Button>
            </Link>
        </Box>
    )
}

export default ErrorPage
