import React from 'react'
import { Paper } from '@mui/material'

const ContentContainer = ({ pt, pb, pl, pr, sx, children }) => {
    return (
        <Paper elevation={0} sx={{ pt, pb, pl, pr, ...sx }}>
            {children}
        </Paper>
    )
}

ContentContainer.defaultProps = {
    pt: 5,
    pb: 5,
    pl: 5,
    pr: 5
}

export default ContentContainer
