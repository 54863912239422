import { Grid } from '@mui/material'
import ContentContainer from '../common/ContentContainer'
import ProfileCard from './ProfileCard'
import TeammateRatingList from '../rating/TeammateRatingList'
import React from 'react'
import BackdropSpinner from '../common/BackdropSpinner'
import NoReportedRatings from './NoReportedRatings'
import RatingDistribution from './RatingDistribution'
import { useProfileContext } from '../../context/ProfileContextProvider'
import ErrorPage from '../error/ErrorPage'
import PlayerNotFound from './PlayerNotFound'
import { useParams } from 'react-router-dom'
import RecentMatch from '../match/RecentMatch'

const ProfilePage = () => {
    const { user } = useParams()
    const { state } = useProfileContext()

    if (state.error?.response?.status === 404) {
        return <PlayerNotFound name={user} />
    }

    if (state.error) {
        return <ErrorPage errorCode={state.error?.response?.status} />
    }

    if (state.loading) {
        return <BackdropSpinner open title="Loading profile..." />
    }

    if (!state.loading && state.ratingsPage?.ratings?.length === 0) {
        return <NoReportedRatings name={state.leagueProfile.summonerName} uuid={state.leagueProfile.uuid} />
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <ContentContainer sx={{ height: '100%' }}>
                    <ProfileCard />
                </ContentContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <ContentContainer sx={{ height: '100%' }}>
                    <RatingDistribution />
                </ContentContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <RecentMatch />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <ContentContainer>
                    <TeammateRatingList />
                </ContentContainer>
            </Grid>
        </Grid>
    )
}

export default ProfilePage
