import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import React from 'react'

const theme = createTheme({
    rating: {
        great: '#EFBF6C',
        good: '#57DCBE',
        ok: '#276880',
        bad: '#FF5757',
        terrible: '#FF2A32'
    },
    graph: {
        empty: '#0e1015',
        filled: '#1d5eff'
    },
    typography: {
        fontFamily: ['Plus Jakarta Display', 'sans-serif'].join(','),
        allVariants: {
            color: '#e2e4e9'
        }
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#181a20'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#e2e4e9'
                }
            }
        }
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#1d5eff'
        },
        background: {
            default: '#0e1015'
        },
        gold: '#e2ac49',
        silver: '#acacac',
        bronze: '#a95940',
        leaderboardDefault: '#1e1e23'
    }
})

const AppThemeProvider = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}

export default AppThemeProvider
