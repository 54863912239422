export const createDefaultReducerState = () => {
    return {
        leagueProfile: { uuid: '', summonerName: '', profileIcon: 0, level: 0 },
        ratingsPage: { ratings: [], page: 0, maxPage: 1 },
        average: 0,
        distribution: { great: 0, good: 0, ok: 0, bad: 0, terrible: 0 },
        loading: false,
        error: {}
    }
}

const profileReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LEAGUE_PROFILE':
            return { ...state, leagueProfile: action.leagueProfile }
        case 'SET_RATINGS_PAGE':
            return { ...state, ratingsPage: action.ratingsPage }
        case 'SET_DISTRIBUTION':
            return { ...state, distribution: action.distribution }
        case 'SET_AVERAGE':
            return { ...state, average: action.average }
        case 'LOAD_START':
            return { ...state, loading: true, error: null }
        case 'LOAD_COMPLETE':
            return { ...state, loading: false }
        case 'SET_ERROR':
            return { ...state, error: action.error }
        default:
            return state
    }
}

export default profileReducer
