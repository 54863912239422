import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import ContentContainer from '../common/ContentContainer'
import { Link } from 'react-router-dom'

const rankingBackgroundColor = {
    1: 'gold',
    2: 'silver',
    3: 'bronze'
}

const getRankingBackgroundColor = (ranking) => {
    const color = rankingBackgroundColor[ranking]
    if (!color) return 'leaderboardDefault'
    return color
}

const Leaderboard = ({ name, data }) => {
    return (
        <>
            <Typography marginBottom={3} variant="h5" fontWeight={600}>
                {name}
            </Typography>
            <ContentContainer sx={{ minWidth: 750 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>RANK</TableCell>
                            <TableCell>PLAYER</TableCell>
                            <TableCell>TOTAL RATINGS</TableCell>
                            <TableCell>SCORE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((entry, i) => (
                            <LeaderboardTableRow
                                key={entry.username}
                                username={entry.username}
                                ranking={i + 1}
                                totalReviews={entry.totalReviews}
                                rating={entry.score}
                            />
                        ))}
                    </TableBody>
                </Table>
            </ContentContainer>
        </>
    )
}

const LeaderboardTableRow = ({ ranking, username, totalReviews, rating }) => {
    return (
        <TableRow>
            <TableCell>
                <RankingBadge ranking={ranking} />
            </TableCell>
            <TableCell>
                <Link to={`/profile/${username}`}>
                    <Typography fontWeight={600}>{username}</Typography>
                </Link>
            </TableCell>
            <TableCell>
                <Typography>{totalReviews}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{rating}</Typography>
            </TableCell>
        </TableRow>
    )
}

const RankingBadge = ({ ranking }) => {
    const color = getRankingBackgroundColor(ranking)
    return (
        <Box
            width={35}
            height={35}
            textAlign="center"
            bgcolor={color}
            borderRadius={10}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Typography fontWeight={600}>{ranking}</Typography>
        </Box>
    )
}

export default Leaderboard
