import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import ProfileSearch from '../search/ProfileSearch'
import RecentRatingList from '../rating/RecentRatingList'

const HomePage = () => {
    return (
        <Box
            mt={20}
            ml={25}
            mr={25}
            mb={20}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="h4" fontWeight={600} sx={{ mb: 10 }}>
                Rate My Teammate
            </Typography>
            <Paper elevation={2} sx={{ borderRadius: 5, padding: 1.5, width: '45%', minWidth: '400px' }}>
                <Box display="flex" justifyContent="center">
                    <ProfileSearch />
                </Box>
            </Paper>
            <Box mt={15}>
                <RecentRatingList />
            </Box>
        </Box>
    )
}

export default HomePage
