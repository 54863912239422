import axios from 'axios'

export const axiosInstance = axios.create({ baseURL: 'https://api.ratemyteammate.com' })

export const getMostRecentGame = (uuid) => {
    return axiosInstance.get(`/league-match/${uuid}`).then((res) => res.data)
}

export const getTopRatedPlayers = () => {
    return axiosInstance.get('/leaderboard/top').then((res) => res.data)
}

export const getLeagueProfile = (name) => {
    return axiosInstance.get(`/league-profile/${name}`).then((res) => res.data)
}

export const getRatingsPage = (uuid, page = 0) => {
    return axiosInstance.get(`/ratings/${uuid}/${page}`).then((res) => res.data)
}

export const getAverageRating = (uuid) => {
    return axiosInstance.get(`/ratings/${uuid}/average`).then((res) => res.data)
}

export const getRatingDistribution = (uuid) => {
    return axiosInstance.get(`/ratings/${uuid}/distribution`).then((res) => res.data)
}

export const getRecentRatings = () => {
    return axiosInstance.get(`/ratings/recent`).then((res) => res.data)
}

export const submitRating = (submissionDTO) => {
    return axiosInstance.post(`/rate`, submissionDTO)
}
