import React, { useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { capitalize } from '../../utils'
import { useTheme } from '@mui/material/styles'
import { useProfileContext } from '../../context/ProfileContextProvider'

const ratingQualityList = ['GREAT', 'GOOD', 'OK', 'BAD', 'TERRIBLE']

const getTotalReviews = (distribution) => Object.values(distribution).reduce((a, b) => a + b, 0)

const mapToBar = (quality, distribution, total) => {
    const count = distribution[quality]
    const filledPercentage = (count / total) * 100
    return { label: capitalize(quality.toLowerCase()), filled: `${filledPercentage}%`, count }
}

const RatingDistribution = () => {
    const { state } = useProfileContext()

    const bars = useMemo(() => {
        const distribution = state.distribution
        if (!distribution) return []
        const total = getTotalReviews(distribution)
        return ratingQualityList.map((quality) => mapToBar(quality, distribution, total))
    }, [state])

    if (!state.distribution) return null

    return (
        <Stack spacing={2}>
            <Typography variant="h5" fontWeight={600}>
                Rating Distribution
            </Typography>
            <Stack spacing={1} width="100%">
                {bars.map((bar) => (
                    <RatingDistributionBar
                        key={bar.label}
                        quality={capitalize(bar.label.toLowerCase())}
                        filled={bar.filled}
                        count={bar.count}
                    />
                ))}
            </Stack>
        </Stack>
    )
}

const RatingDistributionBar = ({ quality, filled, count }) => {
    const theme = useTheme()
    return (
        <Stack spacing={1}>
            <Stack direction="row">
                <Typography sx={{ flexGrow: 1 }}>{quality}</Typography>
                <Typography sx={{ pr: 1 }}>{count}</Typography>
            </Stack>
            <Box sx={{ borderRadius: 3, width: '100%', height: 30, backgroundColor: theme.graph.empty }}>
                <Box sx={{ borderRadius: 3, width: filled, height: 30, backgroundColor: theme.graph.filled }} />
            </Box>
        </Stack>
    )
}

RatingDistribution.defaultProps = {
    distribution: { great: 0, good: 0, ok: 0, bad: 0, terrible: 0 }
}

export default RatingDistribution
