import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import React from 'react'
import ContentContainer from '../common/ContentContainer'

const PlayerNotFound = ({ name }) => {
    return (
        <ContentContainer>
            <Box mt={15} ml={25} mr={25} mb={15} textAlign="center">
                <Typography variant="h5">
                    Hmmmmm. Looks like <span style={{ fontWeight: 600 }}>{name}</span> does not exist. Try searching for
                    a different player.
                </Typography>
                <Link style={{ textDecoration: 'none' }} to="/">
                    <Button sx={{ mt: 5 }} variant="contained">
                        Return to Search
                    </Button>
                </Link>
            </Box>
        </ContentContainer>
    )
}

export default PlayerNotFound
