import React from 'react'
import ProfilePage from '../component/profile/ProfilePage'
import { Box } from '@mui/material'
import ProfileContextProvider from '../context/ProfileContextProvider'
import Header from '../component/common/Header'

const ProfileRoute = () => {
    return (
        <React.Fragment>
            <Header />
            <Box mt={15} ml="20%" mr="20%" mb={15}>
                <ProfileContextProvider>
                    <ProfilePage />
                </ProfileContextProvider>
            </Box>
        </React.Fragment>
    )
}

export default ProfileRoute
