import React from 'react'
import { Rating, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'

const TeammateQualityRating = ({ quality, onQualityChange }) => {
    return (
        <Stack direction="row" spacing={2}>
            <Typography>Overall Rating</Typography>
            <Rating
                name="teammate-quality"
                value={quality}
                precision={1}
                onChange={(event, value) => onQualityChange(value)}
                size="large"
            />
        </Stack>
    )
}

export default TeammateQualityRating
