import React from 'react'
import { Box, Typography } from '@mui/material'

const NoRatings = () => {
    return (
        <Box>
            <Typography variant="h6" fontWeight={600}>
                Ratings
            </Typography>
            <Typography marginTop={2}>No ratings have been submitted for this player.</Typography>
        </Box>
    )
}

export default NoRatings
