import React from 'react'
import { Box, Fade, Modal } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800
}

const SimpleModal = ({ open, onClose, children }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Fade in={open}>
                <Box sx={style}>{children}</Box>
            </Fade>
        </Modal>
    )
}

export default SimpleModal
