import React, { useEffect, useRef, useState } from 'react'
import { getRecentRatings } from '../../api/requests'
import { Box, Card, CardContent, IconButton, Rating, Stack, Typography, useTheme } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { getRelativeTime } from '../../utils'
import Avatar from '@mui/material/Avatar'
import { Link } from 'react-router-dom'

const SCROLL_AMOUNT = 541

const RecentRatingList = () => {
    const [recent, setRecent] = useState([])
    const [scrolling, setScrolling] = useState(false)
    const ref = useRef()

    const onScroll = () => {
        setScrolling(true)
        setTimeout(() => setScrolling(false), 400)
    }

    const next = () => {
        if (scrolling) return
        const max = SCROLL_AMOUNT * recent.length
        if (ref.current.scrollLeft + SCROLL_AMOUNT >= max) {
            ref.current.scrollLeft = 0
            return
        }
        ref.current.scrollLeft += SCROLL_AMOUNT
        onScroll()
    }

    const back = () => {
        if (scrolling) return
        ref.current.scrollLeft -= SCROLL_AMOUNT
        onScroll()
    }

    useEffect(() => {
        getRecentRatings().then((recentRatings) => setRecent(recentRatings))
    }, [])

    return (
        <Box>
            <Typography textAlign="left" variant="h6" fontWeight="600" sx={{ mb: 2 }}>
                Recent Ratings
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
                <IconButton onClick={back} sx={{ height: 36, width: 36 }}>
                    <NavigateBeforeIcon />
                </IconButton>
                <Stack
                    ref={ref}
                    direction="row"
                    spacing={2}
                    sx={{ width: 525, overflow: 'hidden', scrollBehavior: 'smooth' }}
                >
                    {recent?.map((rating) => (
                        <RecentReview
                            key={rating.id}
                            rating={rating.rating}
                            timeCreated={rating.timeCreated}
                            profileIcon={rating.profileIconId}
                            name={rating.summonerName}
                            comment={rating.comment}
                        />
                    ))}
                </Stack>
                <IconButton onClick={next} sx={{ height: 36, width: 36 }}>
                    <NavigateNextIcon />
                </IconButton>
            </Stack>
        </Box>
    )
}

const RecentReview = ({ rating, timeCreated, profileIcon, name, comment }) => {
    const theme = useTheme()
    return (
        <Card elevation={0} sx={{ p: 1, flexShrink: 0, width: '100%' }}>
            <CardContent>
                <Stack spacing={2} direction="row">
                    <Avatar
                        alt="Profile Image"
                        src={`https://raw.communitydragon.org/12.20/game/assets/ux/summonericons/profileicon${profileIcon}.png`}
                        sx={{ border: '2px solid black' }}
                    />
                    <Stack direction="column" spacing={1}>
                        <Link to={`/profile/${name}`}>
                            <Typography>
                                {name}
                                <span style={{ marginLeft: 5, color: theme.palette.text.secondary, fontSize: 14 }}>
                                    {' '}
                                    was reviewed {getRelativeTime(timeCreated)}
                                </span>
                            </Typography>
                        </Link>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Rating size="small" readOnly value={rating} />
                        </Stack>
                        {comment && (
                            <Typography style={{ marginTop: 10 }} sx={{ maxWidth: 500, wordWrap: 'break-word' }}>
                                {comment}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default RecentRatingList
