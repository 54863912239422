import { IconButton, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import TeammateRating from './TeammateRating'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { loadPage } from '../../reducer/profile-reducer-actions'
import { useProfileContext } from '../../context/ProfileContextProvider'
import NoRatings from './NoRatings'
import React from 'react'

const TeammateRatingList = () => {
    const { state, dispatch } = useProfileContext()

    const onPageChange = (pageNumber) => {
        const max = state.ratingsPage?.maxPage
        if (pageNumber < 0) return
        if (pageNumber >= max) return
        loadPage(state.leagueProfile.uuid, pageNumber, dispatch)
    }

    if (state.ratingsPage?.ratings?.length === 0) return <NoRatings />

    return (
        <div>
            <Typography variant="h6" fontWeight={600}>
                Ratings
            </Typography>
            <Stack spacing={1}>
                {state.ratingsPage?.ratings?.map((rating) => (
                    <TeammateRating key={rating.id} rating={rating} />
                ))}
            </Stack>
            <PageSelector page={state.ratingsPage?.page} max={state.ratingsPage?.maxPage} onPageChange={onPageChange} />
        </div>
    )
}

const PageSelector = ({ page, max, onPageChange }) => {
    return (
        <Stack direction="row" alignItems="center" spacing={3}>
            <IconButton disabled={page === 0} onClick={() => onPageChange(page - 1)}>
                <NavigateBeforeIcon />
            </IconButton>
            <Typography>
                {page + 1} of {max}
            </Typography>
            <IconButton disabled={page === max - 1} onClick={() => onPageChange(page + 1)}>
                <NavigateNextIcon />
            </IconButton>
        </Stack>
    )
}

export default TeammateRatingList
