import React, { useId } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const GameRoleDropdown = ({ value, onChange }) => {
    const labelId = useId()
    const selectId = useId()
    return (
        <FormControl>
            <InputLabel id={labelId}>Role</InputLabel>
            <Select id={selectId} labelId={labelId} label="Role" value={value} onChange={onChange}>
                <MenuItem value="unknown">Not Sure</MenuItem>
                <MenuItem value="top">Top</MenuItem>
                <MenuItem value="jungle">Jungle</MenuItem>
                <MenuItem value="mid">Mid</MenuItem>
                <MenuItem value="adc">ADC</MenuItem>
                <MenuItem value="support">Support</MenuItem>
            </Select>
        </FormControl>
    )
}

export default GameRoleDropdown
