import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <Box p={3}>
            <Stack direction="row" alignItems="center" spacing={12}>
                <Link to="/">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <StarIcon color="warning" />
                        <Typography fontWeight={800}>RMT</Typography>
                    </Stack>
                </Link>
                <Link to="/leaderboard">
                    <Typography>Leaderboard</Typography>
                </Link>
            </Stack>
        </Box>
    )
}

export default Header
