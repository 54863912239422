import moment from 'moment'

export const getRelativeTime = (localDateTimeString) => {
    return moment.utc(localDateTimeString).local().fromNow()
}

export const capitalize = (string) => {
    const firstCharacter = string.substring(0, 1).toUpperCase()
    const remaining = string.substring(1, string.length)
    return `${firstCharacter}${remaining}`
}

export const simulateRequest = (response, error, time = 1000) => {
    return new Promise((resolve, reject) => {
        if (response) {
            setTimeout(() => resolve(response), time)
        } else if (error) {
            setTimeout(() => reject(error), time)
        }
    })
}
