import React, { useState } from 'react'
import SimpleModal from '../common/SimpleModal'
import RatingForm from '../form/RatingForm'
import { Alert, Backdrop, Button, Snackbar } from '@mui/material'
import { submitRating } from '../../api/requests'
import { useProfileContext } from '../../context/ProfileContextProvider'
import { loadRatings } from '../../reducer/profile-reducer-actions'

const RatingButton = ({ name, uuid, withSubmitRefresh, buttonName, buttonProps }) => {
    const { state, dispatch } = useProfileContext()
    const [open, setOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [alert, setAlert] = useState({ message: '', severity: 'info' })
    const [alertOpen, setAlertOpen] = useState(false)

    const handleError = (error) => {
        const status = error?.request?.status
        if (status === 429) {
            setAlert({
                message: 'Slow down there speed racer! You can only submit one review per minute.',
                severity: 'error'
            })
            setAlertOpen(true)
        }
    }

    const onClickSubmit = (form) => {
        setSubmitting(true)
        const { role, rating, comment } = form
        submitRating({ uuid, role, rating, comment })
            .then(() => {
                if (withSubmitRefresh) {
                    loadRatings(uuid, state.ratingsPage.page, dispatch)
                }
                setAlert({ message: 'Your rating has been submitted!', severity: 'success' })
                setAlertOpen(true)
            })
            .catch(handleError)
            .finally(() => {
                setSubmitting(false)
                setOpen(false)
            })
    }

    return (
        <React.Fragment>
            <Button onClick={() => setOpen(true)} {...buttonProps}>
                {buttonName || `Rate ${name}`}
            </Button>
            <SimpleModal open={open} onClose={() => setOpen(false)}>
                <RatingForm teammate={name} onClickSubmit={(form) => onClickSubmit(form)} submitting={submitting} />
            </SimpleModal>
            <Backdrop open={submitting} />
            <Snackbar open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
                <Alert severity={alert.severity} elevation={6} onClose={() => setAlertOpen(false)}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

RatingButton.defaultProps = {
    withSubmitRefresh: false,
    buttonName: undefined,
    buttonProps: {
        size: 'large',
        variant: 'contained'
    }
}

export default RatingButton
