import React, { useEffect, useState } from 'react'
import Header from '../component/common/Header'
import { Box } from '@mui/material'
import Leaderboard from '../component/leaderboard/Leaderboard'
import { getTopRatedPlayers } from '../api/requests'

const LeaderboardRoute = () => {
    const [leaderboard, setLeaderboard] = useState()

    useEffect(() => {
        getTopRatedPlayers().then((data) => {
            const sorted = data.sort((a, b) => b.score - a.score)
            setLeaderboard(sorted)
        })
    }, [])

    return (
        <React.Fragment>
            <Header />
            <Box mt={15} mb={15} ml="20%" mr="20%">
                <Leaderboard name="Top 10 Best Rated Players" data={leaderboard} />
            </Box>
        </React.Fragment>
    )
}

export default LeaderboardRoute
